<template>
    <v-container id="regular-tables" fluid tag="section">
      <v-card>
        <v-card-text>
          <VValidationErrors :validationErrors="validationErrors"></VValidationErrors>
        </v-card-text>
        <v-card-title>
          <v-row>
            <v-col cols="12" sm="12" md="5" lg="5">
              <v-menu
                v-model="fromMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="fromData.from"
                    label="From"
                    append-icon="mdi-inbox-arrow-up"
                    readonly
                    v-on="on"
                    outlined
                  ></v-text-field>
                </template>
                <v-date-picker
                  :max="new Date().toISOString().substr(0, 10)"
                  v-model="fromData.from"
                  @input="fromMenu = false">
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="12" md="5" lg="5">
              <v-menu
                v-model="toMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="fromData.to"
                    label="To"
                    append-icon="mdi-inbox-arrow-down"
                    readonly
                    v-on="on"
                    outlined
                  ></v-text-field>
                </template>
                <v-date-picker
                  class="mt-0"
                  :max="new Date().toISOString().substr(0, 10)"
                  v-model="fromData.to"
                  @input="toMenu = false">
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="2">
              <div class="text-center">
                <v-btn color="primary" large dark @click="submit">Filter results</v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
      <base-material-card
        v-if="reports.length"
        icon="mdi-file-chart"
        title="User Reports"
        class="px-5 py-3">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="reports"
          :items-per-page="5"
          class="elevation-0"
          :search="search"
        ></v-data-table>
      </base-material-card>
    </v-container>
</template>

<script>
  import MaterialChartCard from "../../components/base/MaterialChartCard";
  import VValidationErrors from "../../components/base/VValidationErrors";

  export default {
    name: "Subscriptions",
    components: {VValidationErrors, MaterialChartCard},
    data() {
      return {
        fromData: {
          from: null,
          to: null,
        },
        reports: [],
        search: '',
        date: new Date().toISOString().substr(0, 10),
        fromMenu: false,
        toMenu: false,
        validationErrors: {},
        headers: [
          { text: 'Username', value: 'username' },
          { text: 'Coupons Count', value: 'coupons_count' },
          { text: 'Subscribers Count', value: 'subscribers_count' },
        ],
      }
    },
    methods: {
      submit() {
        this.$authApi.get('user-reports', {
          params: this.fromData
        }).then(res => {
          this.reports = res.data.data;
          this.validationErrors = {};
        }).catch(error => {
          if (error.response) {
            this.validationErrors = error.response.data.errors;
          }
        })
      }
    }
  }
</script>

<style scoped>
  .full-width {
    width: 100%;
  }
</style>
